import { useEffect, useState } from 'react';
import { darkTheme, lightTheme, Theme } from '../assets/style/theme';

export function useSessionTheme() {
  const [theme, setTheme] = useState(lightTheme);

  // we need the useEffect otherwise window. will make the build crash
  useEffect(() => {
    const sessionStoredTheme = (window.sessionStorage.getItem('theme') as Theme) ?? Theme.light;
    setTheme(sessionStoredTheme === Theme.light ? lightTheme : darkTheme);
  }, []);

  // const [theme, setTheme] = useState(getInitialThemevalue);

  function toggleTheme() {
    const sessionStoredTheme = (window.sessionStorage.getItem('theme') as Theme) ?? Theme.light;
    if (sessionStoredTheme === Theme.light) {
      window.sessionStorage.setItem('theme', Theme.dark);
      setTheme(darkTheme);
    } else {
      window.sessionStorage.setItem('theme', Theme.light);
      setTheme(lightTheme);
    }
  }

  return {
    theme,
    toggleTheme,
  };
}

import { createGlobalStyle } from 'styled-components';

export const Utilities = createGlobalStyle`
  .page-title {
    font-family: 'Libre Baskerville';
    font-size: clamp(${({ theme }) => theme.font.size.medium}, 1rem + 3vw , ${({ theme }) => theme.font.size.xl});
    font-weight: ${({ theme }) => theme.font.weight['semi-bold']};
    margin-bottom: 4rem;
    margin-top: 2rem;
  }

  .no-content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.color.primary};
    font-size: ${({ theme }) => theme.font.size.medium};
    margin: 4rem;
    transition: color ${({ theme }) => theme.transitionTiming.default};
  }

  .link {
    color: ${({ theme }) => theme.color.link};
    font-weight: ${({ theme }) => theme.font.weight['semi-bold']};
    text-decoration: none;

    svg {
      transition: transform ${({ theme }) => theme.transitionTiming.default} ease;
      fill: ${({ theme }) => theme.color.link};
      height: ${({ theme }) => theme.font.size.base};
      width: ${({ theme }) => theme.font.size.base};
      position: relative;
      top: 3px;
      margin-left: 0.2rem;
    }

    &:hover {
      text-decoration: underline;

      svg {
        transform: translateX(5px);
      }
    }
  }

  .input-text {
    padding: 0.2rem 0.5rem;
    border-color: ${({ theme }) => theme.color.link};
    border-width: 2px;
    border-radius: ${({ theme }) => theme.radius.default};
    font-weight: ${({ theme }) => theme.font.weight.light};;
  }

  .btn-primary {
    -webkit-appearance: none;
    background-color: ${({ theme }) => theme.color.link};
    color: #fff;
    border-radius: ${({ theme }) => theme.radius.default};
    padding: 0.3rem 0.5rem;
    border: none;
    font-weight: ${({ theme }) => theme.font.weight.light};
    cursor: pointer;
  }
`;

import { Link } from 'gatsby';
import React, { useRef } from 'react';
import styled from 'styled-components';
import ToggleThemeButton from './ToggleThemeButton';
import ElisabethLogo from '../assets/svg/ElisabethLogo.svg';

const Header = styled.header`
  display: flex;
  padding-top: 1.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  width: 100%;
  justify-content: space-between;

  &::-webkit-scrollbar {
    display: none;
  }

  .logo path {
    fill: ${({ theme }) => theme.color.primary};
    transition: ${({ theme }) => theme.transitionTiming.default};
  }

  .logo:hover {
    path {
      opacity: 0.8;
    }
  }

  .toggle-theme {
    align-self: self-start;
  }

  #menuToggle {
    position: absolute;
    z-index: -10;
    opacity: 0;
  }

  .hamburger-menu {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 1001;
    cursor: pointer;

    [class|='menu-bar'] {
      width: 2rem;
      height: 0.05rem;
      background: ${({ theme }) => theme.color.primary};
      transition: transform 0.15s;
      border-radius: 2px;
    }

    .menu-bar-2 {
      margin-top: 0.5rem;
    }
    .menu-bar-3 {
      /* This is necessary to fix a bug in FF and safari where one of the bar wouldnt show */
      margin-top: 0.51rem;
    }
  }

  #menuToggle:checked ~ label .hamburger-menu {
    position: fixed;
    .menu-bar-1 {
      transform: translateY(0.7rem) rotate(-45deg);
    }

    .menu-bar-2 {
      opacity: 0;
    }

    .menu-bar-3 {
      transform: translateY(-0.4rem) rotate(45deg);
    }
  }

  #menuToggle:checked ~ .nav {
    visibility: visible;
    opacity: 1;

    .nav__list {
      transform: translateX(0);
    }
  }

  .toggle-theme {
    position: fixed;
    top: 2rem;
    left: 2rem;
  }

  .nav {
    display: flex;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 2rem;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.color.bg};
    z-index: 1000;
    transition: transform ${({ theme }) => theme.transitionTiming.default}, opacity ${({ theme }) => theme.transitionTiming.default},
      visibility ${({ theme }) => theme.transitionTiming.default}, color ${({ theme }) => theme.transitionTiming.default},
      background-color ${({ theme }) => theme.transitionTiming.default};

    .nav__list {
      display: flex;
      margin-top: 5rem;
      flex-direction: column;
      transform: translateX(-100%);
      transition: transform ${({ theme }) => theme.transitionTiming.default}, opacity ${({ theme }) => theme.transitionTiming.default},
        visibility ${({ theme }) => theme.transitionTiming.default}, color ${({ theme }) => theme.transitionTiming.default},
        background-color ${({ theme }) => theme.transitionTiming.default};

      a {
        display: block;
        text-decoration: none;
        letter-spacing: -0.025em;
        font-weight: bold;
        color: ${(props) => props.theme.color.primary};
        font-size: ${({ theme }) => theme.font.size.big};
        margin-bottom: 1rem;
      }
    }
  }

  @media ${({ theme }) => theme.media.query.tablet} {
    .nav {
      display: flex;
      visibility: visible;
      background-color: transparent;
      position: inherit;
      opacity: 1;
      height: auto;
      width: auto;
      padding: 0;

      .nav__list {
        display: flex;
        flex-direction: row;
        margin-top: 0;
        transform: none;

        li {
          margin-right: 1.5rem;
        }

        a {
          font-size: ${({ theme }) => theme.font.size.base};
          font-weight: ${({ theme }) => theme.font.weight.light};
          text-decoration: none;
          color: ${({ theme }) => theme.color.primary};
        }

        a.current {
          padding-bottom: 0.2rem;
          border-bottom: 1px solid ${({ theme }) => theme.color.primary};
        }

        a:hover {
          opacity: 0.8;
        }
      }

      .toggle-theme {
        position: inherit;
      }
    }

    .hamburger-menu-container {
      display: none;
    }
  }
`;

const routes = [
  {
    name: 'Accueil',
    to: '/',
  },
  {
    name: 'À propos',
    to: '/a-propos',
  },
];

export default function PageHeader(): JSX.Element {
  const hamburgerEl: React.MutableRefObject<HTMLInputElement | null> = useRef(null);

  function closeFullScreenMenu() {
    hamburgerEl.current?.click();
  }

  return (
    <Header>
      <Link aria-label="Page d'accueil" to="/">
        <ElisabethLogo className="logo"></ElisabethLogo>
      </Link>

      <input id="menuToggle" aria-hidden="true" hidden type="checkbox" ref={hamburgerEl} />
      <label className="hamburger-menu-container" htmlFor="menuToggle">
        <ul className="hamburger-menu">
          <li className="menu-bar-1"></li>
          <li className="menu-bar-2"></li>
          <li className="menu-bar-3"></li>
        </ul>
      </label>

      <nav className="nav">
        <ul className="nav__list">
          {routes.map((route) => (
            <li key={route.name}>
              <Link to={route.to} onClick={closeFullScreenMenu} activeClassName="current">
                {route.name}
              </Link>
            </li>
          ))}
        </ul>
        <ToggleThemeButton />
      </nav>
    </Header>
  );
}

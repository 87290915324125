import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Theme } from '../assets/style/theme';
import { useThemeUpdate } from './Layout';

const ToggleTheme = styled.button`
  background-color: transparent;
  border: 0;
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

export default function ToggleThemeButton(): JSX.Element {
  const toggleTheme = useThemeUpdate();
  const theme = useTheme();
  const darkTheme = theme.current === Theme.dark;

  return (
    <ToggleTheme aria-label="Toggle dark/light" className="toggle-theme" type="button" onClick={toggleTheme}>
      {darkTheme ? (
        <svg
          className="feather feather-sun"
          fill="none"
          height="24"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="5" />
          <line x1="12" x2="12" y1="1" y2="3" />
          <line x1="12" x2="12" y1="21" y2="23" />
          <line x1="4.22" x2="5.64" y1="4.22" y2="5.64" />
          <line x1="18.36" x2="19.78" y1="18.36" y2="19.78" />
          <line x1="1" x2="3" y1="12" y2="12" />
          <line x1="21" x2="23" y1="12" y2="12" />
          <line x1="4.22" x2="5.64" y1="19.78" y2="18.36" />
          <line x1="18.36" x2="19.78" y1="5.64" y2="4.22" />
        </svg>
      ) : (
        <svg
          className="feather feather-moon"
          fill="none"
          height="24"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
        </svg>
      )}
    </ToggleTheme>
  );
}

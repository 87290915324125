import React from 'react';
import styled from 'styled-components';

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  padding: 1.5rem 0;
  width: 100%;

  /* Wrapper helper class (without the padding) copy because using className on a styled component sucks */
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export default function PageFooter(): JSX.Element {
  return (
    <Footer>
      <span className="footer__copyright">Copyright © {new Date().getFullYear()}</span>
      <span className="footer__name">Elisabeth Pastor</span>
    </Footer>
  );
}

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-propos-tsx": () => import("./../../../src/pages/a-propos.tsx" /* webpackChunkName: "component---src-pages-a-propos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-theme-sanity-theme-slug-current-tsx": () => import("./../../../src/pages/theme/{SanityTheme.slug__current}.tsx" /* webpackChunkName: "component---src-pages-theme-sanity-theme-slug-current-tsx" */)
}


import { DefaultTheme } from 'styled-components';

export enum Theme {
  light = 'light',
  dark = 'dark',
}

// Instead of declaring an interface I could try to do something like
// type Theme = typeof lightTheme
// Like here: https://github.com/kentcdodds/react-workshop-app/blob/main/src/theme.tsx
declare module 'styled-components' {
  export interface DefaultTheme {
    current: Theme;
    color: {
      bg: string;
      primary: string;
      secondary: string;
      link: string;
      border: string;
      success: string;
      error: string;
    };
    font: {
      family: {
        stack: string;
        normal: string;
        title: string;
      };
      size: {
        small: string;
        base: string;
        basePlus: string;
        medium: string;
        big: string;
        xl: string;
      };
      weight: {
        thin: number;
        extraLight: number;
        light: number;
        normal: number;
        medium: number;
        'semi-bold': number;
        bold: number;
      };
    };
    spacing: {
      'inner-card': string;
    };
    radius: {
      default: string;
    };
    transitionTiming: {
      default: string;
    };
    media: {
      size: IScreenSize;
      query: IScreenSize;
    };
  }
}

interface IScreenSize {
  mobileS?: string;
  mobileM?: string;
  mobileL?: string;
  tablet?: string;
  laptop?: string;
  laptopL?: string;
  desktop?: string;
}

const screenSize: IScreenSize = {
  tablet: '768px',
};

// Next time it would be easier to have the styled theme to only contain the changing values, and have a normal object to contain the staticTheme
export const lightTheme: DefaultTheme = {
  current: Theme.light,
  color: {
    bg: '#f3f7f9',
    primary: 'rgb(17, 17, 17)',
    secondary: '#4e4848',
    link: '#171ae6',
    border: '17, 17, 17',
    success: '#52c41a',
    error: '#f5222d',
  },
  font: {
    family: {
      stack:
        '"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
      normal: '"Public Sans"',
      title: '"Libre Baskerville"',
    },
    size: {
      small: '0.7rem',
      base: '1rem',
      basePlus: '1.3rem',
      medium: '1.5rem',
      big: '2rem',
      xl: '3rem',
    },
    weight: {
      thin: 100,
      extraLight: 200,
      light: 300,
      normal: 400,
      medium: 500,
      'semi-bold': 600,
      bold: 700,
    },
  },
  radius: {
    default: '6px',
  },
  spacing: {
    'inner-card': '1.2rem',
  },
  transitionTiming: {
    default: '300ms',
  },
  media: {
    size: {
      ...screenSize,
    },
    query: {
      tablet: `(min-width: ${screenSize.tablet})`,
    },
  },
};

export const darkTheme: DefaultTheme = {
  ...lightTheme,
  current: Theme.dark,
  color: {
    ...lightTheme.color,
    bg: '#0d2538',
    primary: '#ffffff',
    secondary: '#e4e0e0',
    link: '#af9cef',
    border: '255, 255, 255',
  },
};

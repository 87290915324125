import React, { ReactNode, useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { CssReset } from '../assets/style/CssReset';
import { Typography } from '../assets/style/Typography';
import { GlobalStyles } from '../assets/style/GlobalStyles';
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';
import { Utilities } from '../assets/style/Utilities';
import { useSessionTheme } from '../hooks/useSessionTheme';

const AppWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
  }
`;

type layoutProps = {
  children: ReactNode;
};

const ThemeUpdateContext = React.createContext<() => void>(() => {});

export function useThemeUpdate(): () => void {
  return useContext(ThemeUpdateContext);
}

export default function Layout({ children }: layoutProps): JSX.Element {
  const { theme, toggleTheme } = useSessionTheme();

  return (
    <ThemeProvider theme={theme}>
      <ThemeUpdateContext.Provider value={toggleTheme}>
        <CssReset />
        <GlobalStyles />
        <Typography />
        <Utilities />
        <AppWrapper>
          <PageHeader />
          <main className="wrapper">{children}</main>
          <PageFooter />
        </AppWrapper>
      </ThemeUpdateContext.Provider>
    </ThemeProvider>
  );
}

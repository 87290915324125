import { createGlobalStyle } from 'styled-components';

export const Typography = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.font.family.stack};
  }

  div,
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5 {
    transition: color ${({ theme }) => theme.transitionTiming.default};
    color: ${({ theme }) => theme.color.primary};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Libre Baskerville';
  }
`;
